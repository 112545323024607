<template>
  <b-row>

    <h2 class="pl-1">Общая информация</h2>

    <!--  BEFORE TABLE  -->
    <div class="d-flex justify-content-between col-12">
      <b-col
          md="4"
          class="my-1 px-0"
      >
        <b-form-group
            class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Искать"
            />
            <b-input-group-append>
              <b-button
                  :disabled="!filter"
                  @click="filter = ''"
              >
                Очистить
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <!--  CREATE   -->
      <div
          class="my-1 float-right"
      >
        <modal-button
            ripple="rgba(113, 102, 240, 0.15)"
            size="lg"
            name="modal-main"
            modal-title="Создание"
            modalButton="Создать"
            @btn-emit="createSubcategory"
            @open-emit="clearInputs"
            variant="success"
            ref="create-modal-button"
        >
          <template v-slot:button @click="createSubcategory">Создать</template>

          <template v-slot:modal-body>
            <ValidationObserver ref="validation-observer">
              <div class="d-flex flex-wrap">

                <div class="col-12 card__box-shadow mb-2 d-flex flex-wrap">

                  <!--   NAME [RU]   -->
                  <b-col cols="12">
                    <ValidationProvider :name="`Заголовок [RU]`" rules="required" v-slot="{errors}">
                      <b-form-group
                          label="Заголовок [RU]"
                          label-for="title_ru"
                      >
                        <b-form-input
                            v-model="title.ru"
                            id="title_ru"
                            size="lg"
                            placeholder="Заголовок [RU]"
                        />
                      </b-form-group>
                      <span class="validation__red">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-col>

                  <!--   NAME [UZ]   -->
                  <b-col cols="12">
                    <ValidationProvider :name="`Заголовок [UZ]`" rules="required" v-slot="{errors}">
                      <b-form-group
                          label="Заголовок [UZ]"
                          label-for="title_uz"
                      >
                        <b-form-input
                            v-model="title.uz"
                            id="title_uz"
                            size="lg"
                            placeholder="Заголовок [UZ]"
                        />
                      </b-form-group>
                      <span class="validation__red">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-col>

                  <!--   DESCRIPTION [RU]   -->
                  <b-col cols="12">
                    <ValidationProvider :name="`Описания [RU]`" rules="required|max:400" v-slot="{errors}">
                      <b-form-group
                          label="Описания [RU]"
                          label-for="description_ru"
                      >
                        <b-form-textarea
                            v-model="description.ru"
                            id="description_ru"
                            size="lg"
                            rows="6"
                            placeholder="Описания [RU]"
                        />
                      </b-form-group>
                      <span class="validation__red">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-col>

                  <!--   DESCRIPTION [UZ]   -->
                  <b-col cols="12">
                    <ValidationProvider :name="`Описания [UZ]`" rules="required|max:400" v-slot="{errors}">
                      <b-form-group
                          label="Описания [UZ]"
                          label-for="description_uz"
                      >
                        <b-form-textarea
                            v-model="description.uz"
                            id="description_uz"
                            rows="6"
                            size="lg"
                            placeholder="Описания [UZ]"
                        />
                      </b-form-group>
                      <span class="validation__red">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-col>

                  <!--   LINK [RU]   -->
                  <b-col cols="6">
                    <ValidationProvider name="Ссылка [RU]" rules="regex:https" v-slot="{errors}">
                      <b-form-group
                          label="Ссылка [RU]"
                          label-for="link_ru"
                      >
                        <b-form-input
                            v-model="link.ru"
                            id="link_ru"
                            size="lg"
                            placeholder="Ссылка [https]"
                        />
                      </b-form-group>
                      <span class="validation__red">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-col>

                  <!--   LINK [UZ]   -->
                  <b-col cols="6">
                    <ValidationProvider name="Ссылка [UZ]" rules="regex:https" v-slot="{errors}">
                      <b-form-group
                          label="Ссылка [UZ]"
                          label-for="link_uz"
                      >
                        <b-form-input
                            v-model="link.uz"
                            id="link_uz"
                            size="lg"
                            placeholder="Ссылка [https]"
                        />
                      </b-form-group>
                      <span class="validation__red">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-col>

                  <!--   IMAGE   -->
                  <b-col cols="6">
                    <ValidationProvider name="Изображения" rules="size:2048" v-slot="{errors}">
                      <b-form-group
                          label="Изображения (Максимальный размер - 2МБ)"
                          label-for="image"
                      >
                        <b-form-file
                            v-model="image"
                            id="image"
                            size="lg"
                            placeholder="Изображения"
                        />
                      </b-form-group>
                      <span class="validation__red">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-col>

                </div>

              </div>
            </ValidationObserver>
          </template>
        </modal-button>

      </div>
    </div>

    <!--  TABLE  -->
    <b-col cols="12">
      <b-table
          striped
          hover
          responsive
          sort-icon-left
          :busy="isBusy"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
      >

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-1">Идет загрузка ...</strong>
          </div>
        </template>

        <template #cell(title)="data">
          {{ data.item.title.ru }}
        </template>

        <template #cell(link)="data">
          <span v-if="data.item.link && data.item.link.ru !== 'null'">{{ data.item.link.ru }}</span>
        </template>

        <template #cell(description)="data">
          <span class="text__wrap">{{ data.item.description.ru }}</span>
        </template>

        <template #cell(image)="data">
          <span v-if="data.item.image" class="c-img">
             <img :src="`${baseUrl}/${data.item.image}`" alt="">
          </span>
        </template>

        <template #cell(crud_row)="data">
          <div class="d-flex float-right">

            <!--    EDIT    -->
            <modal-button
                class="crud__btn"
                ripple="rgba(113, 102, 240, 0.15)"
                size="lg"
                @btn-emit="updateSubcategory(data.item.id, data.item)"
                @open-emit="getOneSubcategory(data.item.id)"
                :name="`modal-edit-${data.item.id}`"
                modalButton="Сохранить"
                :id="data.item.id"
                modal-title="Редактировать"
                variant="flat-warning"
                ref="update-modal-button"
            >
              <template
                  v-slot:button
              >
                <feather-icon
                    icon="Edit2Icon"
                    size="21"
                />
              </template>

              <template v-slot:modal-body>
                <ValidationObserver ref="validation-observer">
                  <div class="d-flex flex-wrap">

                    <!--   NAME [RU]   -->
                    <b-col cols="12">
                      <ValidationProvider :name="`Заголовок [RU]`" rules="required" v-slot="{errors}">
                        <b-form-group
                            label="Заголовок [RU]"
                            label-for="title_ru"
                        >
                          <b-form-input
                              v-model="title.ru"
                              id="title_ru"
                              size="lg"
                              placeholder="Заголовок [RU]"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <!--   NAME [UZ]   -->
                    <b-col cols="12">
                      <ValidationProvider :name="`Заголовок [UZ]`" rules="required" v-slot="{errors}">
                        <b-form-group
                            label="Заголовок [UZ]"
                            label-for="title_uz"
                        >
                          <b-form-input
                              v-model="title.uz"
                              id="title_uz"
                              size="lg"
                              placeholder="Заголовок [UZ]"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <!--   DESCRIPTION [RU]   -->
                    <b-col cols="12">
                      <ValidationProvider :name="`Описания [RU]`" rules="required|max:400" v-slot="{errors}">
                        <b-form-group
                            label="Описания [RU]"
                            label-for="description_ru"
                        >
                          <b-form-textarea
                              v-model="description.ru"
                              id="description_ru"
                              size="lg"
                              rows="5"
                              placeholder="Описания [RU]"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <!--   DESCRIPTION [UZ]   -->
                    <b-col cols="12">
                      <ValidationProvider :name="`Описания [UZ]`" rules="required|max:400" v-slot="{errors}">
                        <b-form-group
                            label="Описания [UZ]"
                            label-for="description_uz"
                        >
                          <b-form-textarea
                              v-model="description.uz"
                              id="description_uz"
                              rows="5"
                              size="lg"
                              placeholder="Описания [UZ]"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <!--   LINK [RU]   -->
                    <b-col cols="6">
                      <ValidationProvider name="Ссылка" rules="regex:https" v-slot="{errors}">
                        <b-form-group
                            label="Ссылка [RU]"
                            label-for="link_ru"
                        >
                          <b-form-input
                              v-model="link.ru"
                              id="link_ru"
                              size="lg"
                              placeholder="Ссылка [https]"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <!--   LINK [UZ]   -->
                    <b-col cols="6">
                      <ValidationProvider name="Ссылка" rules="regex:https" v-slot="{errors}">
                        <b-form-group
                            label="Ссылка [uz]"
                            label-for="link_uz"
                        >
                          <b-form-input
                              v-model="link.uz"
                              id="link_uz"
                              size="lg"
                              placeholder="Ссылка [https]"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <div class="col-6 px-0">

                      <!--   IMAGE   -->
                      <b-col
                          cols="12"
                          v-if="!image || typeof image !== 'string'"
                      >
                        <ValidationProvider name="Изображения" rules="size:2048" v-slot="{errors}">
                          <b-form-group
                              label="Изображения (Максимальный размер - 2МБ)"
                              label-for="image"
                          >
                            <b-form-file
                                v-model="image"
                                id="image"
                                size="lg"
                                placeholder="Изображения"
                            />
                          </b-form-group>
                          <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                          class="my-2"
                          cols="12"
                          v-else
                      >
                        <div
                            class="c-img delete_btn"
                            @click="deleteImage()"
                        >
                          <img :src="image" alt="">
                        </div>
                      </b-col>
                    </div>

                  </div>
                </ValidationObserver>
              </template>

            </modal-button>

            <!--   DELETE   -->
            <modal-button
                class="crud__btn"
                ripple="rgba(113, 102, 240, 0.15)"
                size="sm"
                :name="`modal-delete-${data.item.id}`"
                modalButton="Удалить"
                modal-title="Удалить"
                variant="flat-danger"
                :id="data.item.id"
                ref="delete-modal"
                @btn-emit="deleteSubcategory"
            >
              <template
                  v-slot:button
              >
                <feather-icon
                    icon="Trash2Icon"
                    size="21"
                />
              </template>

              <template v-slot:modal-body>
                <div class="my-2">
                  Вы действительно хотите удалить эту подкатегорию?
                </div>
              </template>

            </modal-button>

          </div>
        </template>
      </b-table>
    </b-col>

    <!--  PAGINATION  -->
    <b-col
        cols="12"
    >
      <b-pagination
          v-if="showPagination"
          v-model="pagination.current"
          :total-rows="pagination.total"
          :per-page="pagination.per_page"
          align="center"
          size="sm"
          class="my-0"
      />
    </b-col>

  </b-row>
</template>

<script>
import {
  BTable,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BSpinner,
  BCard,
  BFormTextarea,
  BFormFile
} from 'bootstrap-vue'
import ModalButton from "@/views/ui/modals/ModalButton";
import api from '@/services/api'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: "SubCategories",
  components: {
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BSpinner,
    BCard,
    BFormTextarea,
    BFormFile,
    ModalButton,
    ToastificationContent
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      title: {
        en: '',
        ru: '',
        uz: '',
      },
      description: {
        en: '',
        ru: '',
        uz: '',
      },
      baseUrl: process.env.VUE_APP_URL,
      link: {
        ru: '',
        uz: '',
      },
      image: null,
      position: null,
      isBusy: false,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'title',
          label: 'Заголовок',
        },
        {
          key: 'description',
          label: 'Описания',
        },
        {
          key: 'image',
          label: 'Изображения',
        },
        {
          key: 'link',
          label: 'Ссылка',
        },
        {
          key: 'crud_row',
          label: ' ',
        },
      ],
      items: [],
      pagination: {
        current: 1,
        total: 5,
        per_page: 5
      }
    }
  },
  watch: {
    '$route.query': {
      handler(query) {
        // const routeQueryPage = parseInt(query.page)
        this.pagination.current = query.page
      },
      deep: true,
      immediate: true
    },
    'pagination.current': {
      handler(page) {
        this.replaceRouter({
          ...this.query,
          page,
        })
      },
    }
  },

  async created() {
    await this.getSubcategories()
  },

  computed: {
    rows() {
      return this.items.length
    },

    query() {
      return Object.assign({}, this.$route.query)
    },

    hasItems() {
      return this.items.length > 0
    },

    showPagination() {
      return this.hasItems && !this.isBusy
    },

    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => ({
            text: f.label,
            value: f.key,
          }))
    },
  },

  methods: {

    deleteImage() {
      this.image = null
    },

    showToast(variant, text, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: icon,
          variant,
        },
      })
    },

    replaceRouter(query) {
      this.$router.replace({query})
          .catch(() => {
          })
    },

    clearInputs() {
      this.title.en = ''
      this.title.ru = ''
      this.title.uz = ''
      this.description.en = ''
      this.description.ru = ''
      this.description.uz = ''
      this.link.ru = ''
      this.link.uz = ''
      this.image = null
      this.position = ''
    },

    async getSubcategories() {
      this.isBusy = true
      await api.subcategories.fetchSubcategories()
          .then(res => {
            this.items = res.data
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            this.isBusy = false
          })
    },

    async createSubcategory() {
      const isValid = await this.$refs['validation-observer'].validate()
      if (isValid) {

        const formData = new FormData()
        formData.append('title[ru]', this.title.ru)
        formData.append('title[uz]', this.title.uz)
        formData.append('description[ru]', this.description.ru)
        formData.append('description[uz]', this.description.uz)
        formData.append('image', this.image)
        formData.append('link[ru]', this.link.ru)
        formData.append('link[uz]', this.link.uz)
        formData.append('position', this.position)

        api.subcategories.createNewSubcategory(formData)
            .then(res => {
              this.$refs['create-modal-button'].closeModal()
              this.showToast('success', 'Успешно создано!', 'CheckIcon')
            })
            .catch((error) => {
              console.error(error)
              this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
            })
            .finally(() => {
              this.getSubcategories()
              this.clearInputs()
            })
      }
    },

    async getOneSubcategory(id) {
      await api.subcategories.fetchOneSubcategory(id)
          .then(res => {
            this.title.ru = res.data.title.ru
            this.title.uz = res.data.title.uz
            this.description.ru = res.data.description.ru
            this.description.uz = res.data.description.uz
            if (res.data.image) {
              this.image = `${this.baseUrl}/${res.data.image}`
            } else {
              this.image = null
            }
            this.link.ru = res.data.link.ru === 'null' ? '' : res.data.link.ru
            this.link.uz = res.data.link.uz === 'null' ? '' : res.data.link.uz
            this.position = res.data.position
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
          })
    },

    async updateSubcategory(id) {
      const isValid = await this.$refs['validation-observer'].validate()
      if (isValid) {
        const formData = new FormData()
        formData.append('title[ru]', this.title.ru)
        formData.append('title[uz]', this.title.uz)
        formData.append('description[ru]', this.description.ru)
        formData.append('description[uz]', this.description.uz)
        // if (typeof this.image !== 'string') {
        formData.append('image', this.image)
        // }
        formData.append('link[ru]', this.link.ru)
        formData.append('link[uz]', this.link.uz)
        formData.append('position', this.position)

        api.subcategories.updateSubcategory(id, formData)
            .then(res => {
              this.showToast('success', 'Успешно обновлено!', 'CheckIcon')
            })
            .catch((error) => {
              console.error(error)
              this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
            })
            .finally(() => {
              this.$refs['update-modal-button'].closeModal()
              this.getSubcategories()
              this.clearInputs()
            })
      }
    },

    deleteSubcategory(id) {
      api.subcategories.deleteSubcategory(id)
          .then(res => {
            this.$refs["delete-modal"].closeModal()
            this.showToast('success', 'Успешно удалено!', 'CheckIcon')
          })
          .catch((error) => {
            console.error(error)
            this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
          })
          .finally(() => {
            this.getSubcategories()
          })
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },

    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of modals/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="scss" scoped>

.text__wrap {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.delete_btn {
  position: relative;

  &::after {
    content: 'X';
    width: 16px;
    height: 16px;
    position: absolute;
    top: -8px;
    right: -8px;
    font-size: 10px;
    cursor: pointer;
    border: 1px solid #fff;
    color: #000000;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.c-img {
  max-width: 60px;
  max-height: 60px;

  & img {
    width: 100%;
    max-width: 60px;
    object-fit: contain;
  }
}

[dir] .dropdown-item {
  padding: 0;
}

.validation__red {
  color: red;
  font-size: 12px;
  display: block;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 6px;
}

.crud__btn .btn {
  padding: 0.6rem;
}

</style>
